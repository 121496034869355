import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import CaseStudyMtvTemplate from "../modules/caseStudy/CaseStudyMTV.template"

const CaseStudyMtv = ({ data }) => {
  return (
    <Layout>
      <SEO title="Case Study MTV" />
      <CaseStudyMtvTemplate image={data.caseStudyMtv.edges} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    caseStudyMtv: allFile(
      filter: { relativeDirectory: { eq: "caseStudyMtv" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default CaseStudyMtv
