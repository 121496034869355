import React from "react"
import CaseStudyHeaderSection from "./components/CaseStudyHeaderSection"
import CaseStudyDescriptionSection from "./components/CaseStudyDescriptionSection"
import CaseStudyWorkSection from "./components/CaseStudyWorkSection"
import NewsletterSection from "../newsletter/NewsletterSection"

const CaseStudyMtvTemplate = ({ image }) => {
  return (
    <React.Fragment>
      <CaseStudyHeaderSection
        title="MTV"
        description="An iconic classic turns to Hook Points to reimagine brand, relationships and talent"
        image={image}
      />
      <CaseStudyDescriptionSection
        title={
          <h3>
            MTV has been a market innovator for over three decades. When it
            sought new ways to deepen its brand, talent and relationships, we
            turned to Hook Points.
          </h3>
        }
        list={
          <ul>
            <li>
              Created a strategic joint venture partnership with MTV to help
              supply technology as a Hook Point. This helped forge{" "}
              <span>stronger business relationships</span> with premiere talent,
              including celebrities, musicians and athletes
            </li>
            <li>
              Created a NBC/MTV joint venture for extreme sports event business
              DewTour, a <span>strategic relationship</span> with video game
              Rock Band, and a partnership with the media company Vice
            </li>
            <li>
              These technology Hook Points were <span>also leveraged</span> by
              VH1, CMT and Comedy Central
            </li>
            <li>
              The result allowed MTV to{" "}
              <span>
                expand relationships for monetization, growth and opportunities
                to scale
              </span>
            </li>
          </ul>
        }
      />
      <CaseStudyWorkSection
        next={"/case-study-rihanna"}
        text="If you want to dive in deeper about our work with MTV, apply to work with us"
      />
      <NewsletterSection />
    </React.Fragment>
  )
}

export default CaseStudyMtvTemplate
